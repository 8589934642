import {
  HeartIcon,
  
  UserCircleIcon,
  CursorArrowRaysIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { PathName } from "routers/types";
//import MenuBar from "shared/MenuBar/MenuBar";

import isInViewport from "utils/isInViewport";
//import Label from 'components/Label/Label';
import { useAuthStore } from "hooks";


let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: PathName;
  icon: any;
}


// eslint-disable-next-line
const FooterNav = ({}:any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { status, startLogout } = useAuthStore();
  
  
  const NAV: NavItem[] = [
    {
      name: "Reservar",
      link: "/rooms",
      icon: CursorArrowRaysIcon,
    },
    {
      name: "Mi cuenta",
      link: "/account",
      icon: HeartIcon,
    },
    { 
      name: status === 'authenticated' ? "Log out" : "Log in",
      link: "/login",
      icon: status === 'authenticated' ? ArrowRightOnRectangleIcon : UserCircleIcon,
    
    }
  ];
  

  
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    // SHOW _ HIDE MAIN MENU
    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }

      containerRef.current.classList.add("FooterNav--hide");
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("FooterNav--hide");
    }

    WIN_PREV_POSITION = currentScrollPos;
  };

  const handleLogOut = () => {
    startLogout() // Llama a la función startLogOut al hacer clic en "Log Out"
  };

  return (
    <div
      ref={containerRef}
      className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center ">
        {/* MENU */}
        {NAV.map((item, index) => {
          const active = location.pathname === item.link;
          return item.link ? (
            <Link
              key={index}
              to={item.link}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
              onClick={item.name === "Log out" ? handleLogOut : undefined} // Agrega un manejador de eventos onClick para "Log Out"
            >
              <item.icon
                className={`w-6 h-6 ${active ? "text-purple-600" : ""}`}
              />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
              {/* <span className="text-[11px] leading-none mt-1">{status}</span> */}
              
            </Link>
          ) : (
            <div
              key={index}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              <item.icon iconClassName="w-6 h-6" className={``} />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </div>
          );
        })}
        {/* {
          
        status==='authenticated' ? (
        <Link
            to={'/login'}
            onClick={startLogout}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 text-neutral-900 dark:text-neutral-100" `}
            >
              <UserCircleIcon className={``} />
              <span className="text-[11px] leading-none mt-1">Log-out</span>
            
      </Link>
      ) : (
        <Link
            to={'/account'}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 text-neutral-900 dark:text-neutral-100" `}
            >
              <UserCircleIcon className={`w-6 h-6 text-purple-600`} />
              <span className="text-[11px] leading-none mt-1">Log-in</span>
           </Link>   
      )} */}
      </div>
    </div>
  );
};

export default FooterNav;
