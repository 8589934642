
import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { useAuthStore } from "hooks";
//import { previousDay } from "date-fns";
//import { format, parseISO } from 'date-fns';
//import { response } from "express";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'



export interface AccountPageProps {
  className?: string;
}



const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { user, startUpdateUser } = useAuthStore();
  const [msg, setMsg] = useState("");
  // Nuevo estado para almacenar los datos del usuario
  const [userData, setUserData] = useState({
    uid: user.uid,
    name: user.name,
    email: user.email,
    phone: user.phone || '',  // Convertir undefined o null a cadena vacía
    address: user.address || '',
    dateBirth: user.dateBirth || '',
    rol: user.rol || '',
  });

  /*uid: user.uid,
  name: user.name,
  email: user.email,
  phone: user.phone,
  address: user.address,
  dateOfBirth: user.dateOfBirth,
  rol: user.rol,
*/
  const onInputChange = ({ target }: any) => {

    setUserData({
      ...userData,
      [target.name]: target.value,
    });
  };



  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  


    try {
       const msg = await startUpdateUser(userData); // Envía los datos actualizados al servidor
       setMsg(msg);
      console.log(msg);
    } catch (error) {
      console.error("Error al actualizar el usuario:", error);
    }
  };


  useEffect(() => {
    setUserData({
      uid: user.uid,
      name: user.name,
      email: user.email,
      phone: user.phone || '',
      address: user.address || '',
      dateBirth: user.dateBirth || '',
      rol: user.rol || '',
    });
  }, [user]);





  //console.table(userData)


  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Mi cuenta || Barcelona Room Karaoke</title>
      </Helmet>
      <CommonLayout>
        <div className=" my-0 mb-12 sm:my-12 space-y-6 sm:space-y-8">
          {/* HEADING */}
          <form className="grid grid-cols-1  gap-6" onSubmit={handleSubmit}>
            <h2 className="text-3xl font-semibold">Información de la cuenta</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex flex-col md:flex-row">
              <div className="flex-shrink-0 flex items-start">
                <div className="relative rounded-full overflow-hidden flex">
                  <Avatar sizeClass="w-32 h-32" />
                  <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                        stroke="currentColor"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="mt-1 text-xs">Cambiar Imagen</span>
                  </div>
                  <input
                    type="file"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                <div>
                  <Label>Nombre</Label>
                  <input
                    type="text"
                    className="mt-1.5"
                    value={userData.name}
                    name="name"
                    onChange={onInputChange}
                  />
                </div>


                {/* ---- */}
                <div>
                  <Label>Email</Label>
                  <Input
                    className="mt-1.5"
                    type="email"
                    value={userData.email}
                    name="email"
                    onChange={onInputChange}

                  />
                </div>
                {/* ---- */}
                <div className="max-w-lg">
                  <Label >Fecha de nacimiento</Label>

                  <Input
                    className="mt-1.5"
                    type="date"
                    value={userData.dateBirth}
                    name="dateBirth"
                    onChange={onInputChange}
                  />

                </div>
                {/* ---- */}
                <div>
                  <Label>Dirección</Label>
                  <Input
                    className="mt-1.5"
                    value={userData.address}
                    name="address"
                    onChange={onInputChange}
                  />
                </div>
                {/* ---- */}
                <div>
                  <Label>Numero de telefono</Label>
                  {/*<Input
                    className="mt-1.5"
                    value={userData.phone}
                    name="phone"
                    onChange={onInputChange}
                  />*/}

                  <PhoneInput
                    country={'es'}
                    placeholder="Ingrese su número"
                    inputStyle={{
                      color: "#e5e7eb",         // Color del texto
                      background: "transparent", // Fondo transparente
                      borderRadius: "15px",      // Borde redondeado del contenedor
                      border: "1px solid #2c3e50",
                      width: "100%",
                      height: "45px"

                    }}
                    value={userData.phone}
                    buttonStyle={{
                      background: "transparent",
                      border: "none"

                    }}

                    onChange={(value: any) => onInputChange({ target: { name: "phone", value: value } })}
                  />


                </div>


                {msg && (
              <p className=" text-green-500 text-left">{msg}</p>

            )}

                <div className="pt-2">
                  <ButtonPrimary type="submit">Actualizar</ButtonPrimary>
                </div>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;