import React, { FC, useEffect, useState } from "react";
//WARNING import moment from "moment";
import "./NavBarCalendarRoom/NavBarCalendarRoom";
import { es } from "date-fns/locale";
import "./CalendarReservation.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import { useReservationStore, useSlotStore, useAuthStore } from "../../hooks";
import { Car } from "shared/Navigation/InformacionCarrito";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { useRoomStore } from "../../hooks";
//import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { utcToZonedTime, format } from 'date-fns-tz';
//import { MessageCircleMore } from "lucide-react";
//import { Tooltip } from 'react-tooltip';


<link
  rel="stylesheet"
  href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css"
></link>;



interface Reservation {
  _id: string;
  start: string;
  end: string;
  date: string;
  npersons: number;
  price: string;
  slot: string;
  typereservation: string;
  statuspayment: string;
  usernote: string;
  user: {
    id: string;
    name: string;
    email: string;
    phone: string;
  };
  room: {
    id: string;
    name: string;
    capacidadMin: string;
    capacidadMax: string;
  },
  totalPayed: string;
  __v: number;
}

interface Room {
  capacidad: string;
  capacidadMin: string;
  id: string;
  images: Array<{ url: string }>;
  name: string;
  services: Array<{
    a: string;
    b: string;
    c: string;
    d: string;
    e: string;
  }>;
}


interface Slot {
  id: string;
  room: string;
  start: string;
  end: string;
  price: string;
  dayOfWeek: string;

}
interface PropsRoomCalendar {

  rooms?: Room[];
}



const CalendarReservation: FC<PropsRoomCalendar> = () => {
  const [isOpen, setIsOpen] = useState(false); //state para modal de informacion de la reserva
  const [isOpenAdmin, setIsOpenAdmin] = useState(false); //state para hacer una reserva desde el administrador
  const [modalOpenRoom, setModalOpenRoom] = useState(false)
  const [codeReservation, setCodeReservation] = useState("")

  //Datos formulario crear reserva Admin
  const [formValues, setFormValues] = useState({
    startR: "",
    endR: "",
    dateR: "",
    salaName: "",
    salaIdR: "",
    npersonsR: "",
    userNameR: '',
    userPhoneR: '',
    userEmailR: '',
    slotR: "",
    priceR: "",
    typereservationR: "",
    statusPaymentR: "",
  });
  //Tipo de reserva
  //1: Reservado
  //2: Bloqueado o cerrado por el admin

  //Aqui trae el json que contiene los slot de las salas
  const {
    reservations,
    getReservations,
    closeRoom,
    openRoom,
    car,
    getReservationById,
    createReservationAdmin
  } = useReservationStore();

  const [reservaData, setReservaData] = useState<Reservation>({
    _id: "",
    start: "",
    end: "",
    date: "",
    npersons: 0,
    user: {
      id: "",
      name: "",
      email: "",
      phone: "",
    },
    room: {
      id: "",
      name: "",
      capacidadMin: "",
      capacidadMax: "",
    },
    totalPayed: "",
    slot: "",
    price: "",
    typereservation: "",
    statuspayment: "",
    usernote: "",
    __v: 0,
  });


  //Estados para poder mostrar la hora de inicio y fin cuando el admin quiera hacer una reserva
  const [horaInicioSlot, setInicioSlot] = useState("");
  const [horaFinalSlot, setFinalSlot] = useState("");
  const [capacidadSalaState, setCapacidadSala] = useState("");
  const [salaNameState, setSalaName] = useState("");
  const { rooms, getRooms } = useRoomStore();


  const onInputChanged = ({ target }: any) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };


  //Modal de reserva para el admin:
  //capacidadSala:string,salaName:string ,capacidadMin:string
  const openModalAdmin = (slot: Slot, codeSlot: string, sala: Room, fechaReservation: Date) => {

    formValues.startR = slot.start;
    formValues.salaName = sala.name;
    formValues.salaIdR = sala.id;
    formValues.endR = slot.end;
    formValues.dateR = fechaReservation.toString();
    formValues.npersonsR = sala.capacidad;
    formValues.slotR = codeSlot;
    formValues.priceR = (parseFloat(slot.price) * parseFloat(sala.capacidadMin)).toFixed(2);


    formValues.typereservationR = "booked";

    setIsOpenAdmin(true);
    setInicioSlot(slot.start);
    setFinalSlot(slot.end);
    setCapacidadSala(sala.capacidad);
    setSalaName(sala.name);
  }


  const closeModalAdmin = () => {
    setIsOpenAdmin(false)
  }

  //Abrir modal de abrir sala
  const handleModalOpenRoom = (fechaCodeSlot: String, slot: Slot) => {
    setCodeReservation(fechaCodeSlot + slot.id)
    setModalOpenRoom(true)
  }

  const handleCloseModalOpenRoom = () => {
    setModalOpenRoom(false)
  }



  // Manejar clic fuera del modal para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (isOpenAdmin && !event.target.closest(".modal-content-admin")) {
        closeModalAdmin();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenAdmin]);
  // Manejar tecla Escape para cerrar el modal
  useEffect(() => {
    const handleEscKey = (event: any) => {
      if (isOpenAdmin && event.keyCode === 27) {
        closeModalAdmin();
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isOpenAdmin]);


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { userNameR, userEmailR, userPhoneR } = formValues;
    // eslint-disable-next-line   
    const data = await createReservationAdmin(
      formValues.priceR,
      formValues.startR,
      formValues.endR,
      formValues.slotR,
      formValues.salaIdR,
      userNameR,
      userEmailR,
      userPhoneR,
      formValues.dateR,
      formValues.typereservationR,
      formValues.statusPaymentR
    ).then(() => {
      getReservations({ date: fechaSeleccionada });
      closeModalAdmin();
    });

  }


  //Modal Informacion de la reserva:
  const abrirModal = async (slot: Slot) => {
    const dato = reservations.find(
      (reservation: any) => reservation.slot === `${fechaCodeSlot}${slot.id}`
    );
    try {

      const reservationData = await getReservationById(dato.id);

      setReservaData({
        ...reservationData.reservation
      })
      console.log("hola123", reservationData.reservation)
    } catch (error) {
      console.error("Error al obtener la reserva:", error);
    }

    setIsOpen(true);


  };

  //Modal Informacion de la reserva:
  /*WARNING
  const openRoomModal = async (slot: Slot) => {
    const dato = reservations.find(
      (reservation: any) => reservation.slot === `${fechaCodeSlot}${slot.id}`
    );
    try {

      const reservationData = await getReservationById(dato.id);

      setReservaData({
        ...reservationData.reservation
      })



    } catch (error) {
      console.error("Error al obtener la reserva:", error);
    }

    setIsOpen(true);


  };*/


  const cerrarModal = () => {
    setIsOpen(false);
  };



  // Manejar clic fuera del modal para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (isOpen && !event.target.closest(".modal-content")) {
        cerrarModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  // Manejar tecla Escape para cerrar el modal
  useEffect(() => {
    const handleEscKey = (event: any) => {
      if (isOpen && event.keyCode === 27) {
        cerrarModal();
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isOpen]);



  const timeZone = 'Europe/Madrid';
  const fecha = utcToZonedTime(new Date(), timeZone);

  const dia = format(fecha, 'd');
  const mes = format(fecha, 'M');
  const año = format(fecha, 'yy');
  // Convertir día y mes a números
  const diaNumero = Number(dia);
  const mesNumero = Number(mes);

  const diaFormateado = diaNumero < 10 ? "0" + dia : dia;
  const mesFormateado = mesNumero < 10 ? "0" + mes : mes;

  const [fechaSeleccionada, setFechaSeleccionada] = useState<Date>(fecha);
  const [fechaCodeSlot, setFechaCodeSlot] = useState<string>(
    `${diaFormateado}${mesFormateado}${año}`
  );









  const { getSlots, slots } = useSlotStore();
  const { user } = useAuthStore();
  //const [fechaActual, setFechaActual] = useState<Date>(utcToZonedTime(new Date(), timeZone));




  //aqui se actualiza dependiendo si el slot se borra en el carrito, vuelve a su estado normal
  const isSlotReserved = (slotId: string) => {
    return car.some((item: Car) => item.id === slotId);
  };

  const handleFechaChange = (date: Date) => {
    setFechaSeleccionada(date);

    /*DOM:0,LUN:1,M:2*/
  };

  const handlePrevDay = () => {
    //const prevDay = new Date(fechaActual.getTime() - 24 * 60 * 60 * 1000);
    //setFechaActual(prevDay);
    const prevDay = new Date(fechaSeleccionada.getTime() - 24 * 60 * 60 * 1000);

    setFechaSeleccionada(prevDay);
    // Lógica para actualizar los datos de acuerdo a la fecha seleccionada
  };

  const handleNextDay = () => {
    //const nextDay = new Date(fechaActual.getTime() + 24 * 60 * 60 * 1000);
    //setFechaActual(nextDay);

    const nextDay = new Date(fechaSeleccionada.getTime() + 24 * 60 * 60 * 1000);
    setFechaSeleccionada(nextDay);
    // Lógica para actualizar los datos de acuerdo a la fecha seleccionada
  };


  useEffect(() => {
    getSlots({ dayOfWeek: fechaSeleccionada.getDay() });
    getReservations({ date: fechaSeleccionada });
    getRooms();

    //Es para que cada slot tenga un id unico
    const dia = fechaSeleccionada.getDate();
    const mes = fechaSeleccionada.getMonth() + 1;
    const año = fechaSeleccionada.getFullYear() % 100;

    const diaFormateado = dia < 10 ? "0" + dia : dia;
    const mesFormateado = mes < 10 ? "0" + mes : mes;
    setFechaCodeSlot(`${diaFormateado}${mesFormateado}${año}`);
    //--------
  }, [fechaSeleccionada]);


  return (
    <div className="sticky">
      <div className="filtros">
        <i className="las la-sliders-h"></i>
        <a href="/#">Filtros</a>
      </div>

      <div className="inputDateReservation mb-10 mt-10  ">
        <button onClick={handlePrevDay}>{"<"}</button>
        <DatePicker
          id="fechaSelector"
          selected={fechaSeleccionada}
          onChange={handleFechaChange}
          dateFormat="EEEE dd 'de' MMM"
          locale={es}
        />

        <i className="las la-calendar-minus"></i>
        <button onClick={handleNextDay}>{">"}</button>
      </div>

      {/* <div className="NavCalendarRoom">
        <NavBarCalendarRoom />
      </div> */}

      <div className="sala-horario-container">
        {rooms.map((room: Room) => (
          <div key={room.id} className="sala-item">
            <div className="horarios-disponibles">
              <div className="salaImage">
                <h3>{room.name}</h3>

                {/* <p className="sala-capacidad">{sala.capacidad}</p> */}
                {/*<p className="sala-ubicacion">{sala.ubicacion}</p>*/}
                <img src={room.images[0].url} alt={room.name} />

                {/* <img src={salaImages[sala.id]} alt={`Sala ${sala.name}`} /> */}
                <div className="absolute top-3 left-5 rounded-2xl bg-purple-600 text-sm text-white p-1">
                  <i className="las la-user text-[16px]"></i> Min{" "}
                  {room.capacidadMin} / Max {room.capacidad}
                </div>
              </div>
              <div className="contenedor-horario-slot">
                {slots
                  .filter((slot: Slot) => slot.room === room.id)
                  .map((slot: Slot, index: number) => {
                    // Verificar si el slot actual está en las reservaciones
                    const reservation = Array.isArray(reservations) ? reservations.find(
                      (reservation: any) =>
                        reservation.slot === `${fechaCodeSlot}${slot.id}`
                    ) : []

                    const isReserved = !!reservation;
                    const isClosed =
                      isReserved && reservation.typereservation === "closed";

                    return (
                      <div
                        key={index}
                        className={`horario-slot2 ${isReserved ? (isClosed ? "cerrado" : "reservad") : ""
                          } ${isSlotReserved(`${fechaCodeSlot}${slot.id}`)
                            ? "sala-reservada1"
                            : ""
                          }`}
                      >
                        {!isReserved ? (
                          <div className="">
                            {isSlotReserved(`${fechaCodeSlot}${slot.id}`) ? (
                              <div>
                                <p className="mt-5 sm:mt-5 md:mt-5 lg:mt-10 xl:mt-10">
                                  Sala Reservada
                                </p>
                                <button
                                  onClick={() => abrirModal(slot)}
                                  className="focus:outline-none  text-sm bg-teal-500 mt-2 hover:text-gray-200 text-white py-2 px-2 justify-center items-center rounded"
                                >
                                  Info. Reserva
                                </button>
                                {/* Modal */}

                              </div>
                            ) : (
                              <div className="w-full flex flex-col text-[1em] lg:text-base xl:text-xl">
                                {slot.start} - {slot.end}
                                <br />
                                <p className="precio2 text-[1em] lg:text-base xl:text-xl">{slot.price}€ /Pers.</p>
                              </div>
                            )}

                            <div>
                              <div className="w-full justify-center items-center flex flex-row lg:flex-col">
                                {!isSlotReserved(
                                  `${fechaCodeSlot}${slot.id}`
                                ) && (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          closeRoom(
                                            room.id,
                                            0,
                                            slot.start,
                                            slot.end,
                                            `${fechaCodeSlot}${slot.id}`,
                                            room.capacidad,
                                            user.uid,
                                            fechaSeleccionada,
                                            "closed"
                                          );
                                        }}
                                        className={`focus:outline-none w-full lg:w-[65%] text-sm flex items-center justify-center hover:text-gray-200 bg-teal-500 text-white mr-1 lg:mr-0 p-2 py-1 lg:my-2  xl:p-2  rounded `}
                                      >
                                        Cerrar
                                      </button>
                                      {/*<input type="text" id={`${fechaCodeSlot}${slot.id}`} />*/}
                                      <button


                                        onClick={() => openModalAdmin(
                                          slot,
                                          `${fechaCodeSlot}${slot.id}`,
                                          room,

                                          fechaSeleccionada
                                        )}
                                        className={`focus:outline-none w-full lg:w-[65%] text-sm flex items-center hover:text-gray-200 justify-center bg-teal-500 text-white  p-2 lg:mb-3 py-1  xl:p-2  rounded `}
                                      >
                                        Reservar
                                      </button>

                                    </>
                                  )}
                              </div>

                            </div>
                          </div>
                        ) : (
                          <div className=" text-white mt-2 grid justify-items-center items-center">
                            <span className="text-[1em] lg:text-base xl:text-xl">{isClosed ? "Sala cerrada" : "Sala reservada"}</span>

                            <span className="text-[1em] lg:text-base xl:text-xl">
                              {slot.start} - {slot.end}
                            </span>
                            {modalOpenRoom && (

                              <div className="fixed z-10 inset-0 ">
                                <div className="flex items-center justify-center min-h-screen p-4">
                                  <div className="bg-purple-900 border-black border-2 grid rounded-2xl shadow-lg p-6 modal-content">
                                    <h2 className="text-2xl text-slate-200 font-bold mb-4">
                                      ¿Desea abrir la sala?
                                    </h2>
                                    <p className="text-lg text-slate-200 font-normal">Si abre la Sala se borrara la reserva realizada</p>

                                    <div className="mt-5">
                                      <button
                                        onClick={() => {
                                          openRoom(codeReservation, fechaSeleccionada);
                                          handleCloseModalOpenRoom();
                                        }}
                                        className={`focus:outline-none text-sm bg-lime-700 hover:bg-lime-800 hover:text-gray-200 text-white py-2 px-2 m-2 rounded `}
                                      >
                                        Aceptar
                                      </button>
                                      <button
                                        onClick={() => handleCloseModalOpenRoom()}
                                        className={`focus:outline-none text-sm bg-red-600 hover:bg-red-700 hover:text-gray-200 text-white py-2 px-2 m-2 rounded `}
                                      >
                                        Calcelar
                                      </button>
                                    </div>

                                  </div>
                                </div>
                              </div>

                            )}


                            {isClosed ? (
                              //Reserva cerrada
                              <>

                                {/*onClick={() => openRoom(`${fechaCodeSlot}${slot.id}`, fechaSeleccionada)}*/}
                                <button
                                  onClick={() => handleModalOpenRoom(fechaCodeSlot, slot)}
                                  className={`focus:outline w-full lg:w-[85%] text-sm flex items-center justify-center bg-red-600 hover:text-gray-200 text-white p-[5px] mb-2 rounded `}
                                  type="button"
                                >
                                  Abrir sala
                                </button>



                              </>


                            ) : (


                              // Reserva no cerrada
                              <div className="w-full justify-center items-center flex flex-row lg:flex-col ">

                                {
                                /*<div data-tooltip-id="my-tooltip" data-tooltip-content={reservaData.usernote}>
                                  <MessageCircleMore className="text-white absolute top-0 left-0 hover:text-gray-900 cursor-pointer" />
                                </div>
                                <Tooltip id="my-tooltip" />
                                */}

                                <button
                                  onClick={() => abrirModal(slot)}
                                  className="focus:outline-none -full lg:w-[85%] text-sm bg-teal-500  hover:text-gray-200 text-white justify-center items-center mr-1 lg:mr-0 p-2 py-1 lg:my-2  xl:p-2 rounded"
                                  type="button"
                                >
                                  Info. Sala
                                </button>
                                <button
                                  onClick={() => handleModalOpenRoom(fechaCodeSlot, slot)}
                                  className={`focus:outline-none -full lg:w-[85%] text-sm flex items-center justify-center bg-red-600 hover:text-gray-200 text-white p-2 lg:mb-3 py-1  xl:p-2 rounded `}
                                  type="button"
                                >
                                  Abrir sala
                                </button>

                                <div>



                                  {/* Modal de informacion de la reserva */}
                                  {isOpen && (
                                    <div className="fixed inset-0 flex justify-center items-center z-10">
                                      <div className="flex items-center justify-center min-h-screen p-4 w-[400px] sm:w-[400px] md:w-[300px] lg:w-[500px] xl:w-[600px]">
                                        <div className="bg-purple-900 border-2 border-black grid rounded-2xl shadow-lg p-10 modal-content">

                                          <div className=" ">
                                            <div className="flex flex-col w-full mb-5">
                                              <h2 className="text-2xl font-bold mb-4 uppercase">
                                                Datos de la reserva
                                              </h2>
                                              <p className="text-start text-xl font-normal mb-2">Sala: <span className=" font-bold">{reservaData.room.name}</span></p>
                                              <p className="text-start text-xl font-normal mb-2">Inicio: <span className=" font-bold">{reservaData.start}</span> </p>
                                              <p className="text-start text-xl font-normal mb-2">Final: <span className=" font-bold">{reservaData.end}</span></p>
                                              <p className="text-start text-xl font-normal mb-2">Reserva para: <span className=" font-bold">{reservaData.room.capacidadMin}</span> personas</p>
                                              <p className="text-start text-xl font-normal">Estado de pago: <span className=" font-bold">{reservaData.statuspayment.toLowerCase() === "paid" ? "Pagado" : "Pendiente de pago"}</span></p>

                                            </div>

                                            <hr className=" border-black border-2 mb-5" />

                                            <div className="flex flex-col w-full mb-5">
                                              <h2 className="text-2xl font-bold mb-4 uppercase">
                                                Datos del cliente
                                              </h2>
                                              <p className="text-start text-xl font-normal mb-2">Nombre del cliente: <span className=" font-bold">{reservaData.user.name}</span> </p>
                                              <p className="text-start text-xl font-normal mb-2">Email: <span className="font-bold">{reservaData.user.email}</span></p>
                                              <p className="text-start text-xl font-normal mb-2">WhatsApp: <a rel="noreferrer" className=" font-bold hover:text-slate-300" href={"https://wa.me/" + reservaData.user.phone + "?text=¡Estoy+interesado!"} target="_blank"> {reservaData.user.phone}</a></p>{/*Agregar a whatsapp https://wa.me/######?text=¡Estoy+interesado! */}
                                              <p className="text-start text-xl font-normal mb-2">Nota del Cliente: <span className="font-bold">{reservaData.usernote}</span></p>
                                              {/*<p className="text-start text-xl font-normal">{reservaData.statuspayment.toLowerCase() === "paid" ? "Pagado" : "Pendiente de pago"}</p>*/}

                                            </div>


                                          </div>
                                          <button
                                            onClick={() => cerrarModal()}
                                            className={`focus:outline-none text-sm bg-teal-500 hover:text-gray-200 text-white py-2 px-2 m-2 rounded `}
                                          >
                                            Cerrar
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/*Modal para que el admin pueda hacer una reserva */}



                                </div>


                              </div>

                            )}


                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
            {isOpenAdmin && (
              <div className="fixed z-[100] inset-0 overflow-y-auto">
                <div className="flex items-center  justify-center min-h-screen p-4">
                  <div className="bg-purple-900 min-w-[400px] grid rounded-2xl shadow-lg p-6 modal-content-admin">
                    <h2 className="text-xl text-gray-300 text-center font-bold mb-4">
                      Crear Reserva
                    </h2>
                    <div className="">
                      <form onSubmit={handleSubmit} className=" w-full text-start text-slate-200" >
                        <div className="mb-2 font-bold text-lg">
                          <p>Inicio: {horaInicioSlot}</p>

                        </div>
                        <div className="mb-2 font-bold text-lg ">
                          <p>Final: {horaFinalSlot}</p>
                          <p>Sala: {salaNameState}</p>
                          <p>Capacidad de sala: {capacidadSalaState}</p>
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="mb-2 font-bold text-lg "
                          >
                            Nombre:
                          </label>

                          <Input
                            type="text"
                            name="userNameR"
                            value={formValues.userNameR}
                            onChange={onInputChanged}
                            className="w-full font-normal  border-slate-200 rounded-md shadow-sm focus:ring-white focus:border-white"

                          />

                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="telefono"
                            className="mb-2 font-bold text-lg"
                          >
                            Teléfono:
                          </label>
                          <Input
                            type="tel"
                            name="userPhoneR"
                            value={formValues.userPhoneR}
                            onChange={onInputChanged}
                            className="w-full font-normal border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="mb-2 font-bold text-lg"
                          >
                            Email:
                          </label>
                          <Input
                            type="email"
                            name="userEmailR"
                            value={formValues.userEmailR}
                            onChange={onInputChanged}
                            className="w-full font-normal border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                          />

                        </div>
                        <div className="mb-4">
                          <label htmlFor="rol" className="mb-2 font-bold text-lg">Estado de pago</label>
                          <Select
                            name="statusPaymentR"
                            value={formValues.statusPaymentR}
                            onChange={onInputChanged}
                            className="w-full font-normal border-gray-300  rounded-md shadow-sm focus:ring-gray-300 focus:border-gay-300 "
                          >
                            <option value="" >Estado de pago</option>
                            <option value="pending" >Pendiente de pago</option>
                            <option value="paid">Pagado</option>
                          </Select>
                        </div>

                        <button
                          type="submit"
                          className={`focus:outline-none w-full text-sm bg-teal-500 hover:text-gray-200 text-white py-2 px-2 mt-2 rounded `}

                        >
                          Guardar
                        </button>
                      </form>
                    </div>
                    <button
                      onClick={() => closeModalAdmin()}
                      className={`focus:outline-none w-full text-sm bg-teal-500 hover:text-gray-200 text-white py-2 px-2 mt-4 rounded `}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

        ))}
      </div>

    </div>
  );
};

export default CalendarReservation;
