import { FC, useEffect, useState } from "react";
import { useUsersStore } from "../../hooks";
import avatar1 from "images/avatars/Image-1.png";
//import { CardHeader } from "@material-tailwind/react";
import {

  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Typography,

  Input,



} from "@material-tailwind/react";
//import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import MyReservation from "../../containers/MyReservationPage/MyReservation";
import { X } from "lucide-react";

// eslint-disable-next-line
const registerFormFields = {
  uid: "",
  name: "",
  phone: "",
  email: "",
  address: "",
  rol: "",
  dateBirth: "",
};

const UserList: FC<any> = () => {
  const imgUrl = avatar1;
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line
  const [selectedUser, setSelectedUser] = useState<any>(null);
  //const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const { deleteUser, getUsers, updateUser, users } = useUsersStore();
  const [isOpenDelet, setIsOpenDelet] = useState(false);
  const [openUserReservations, setOpenUserReservations] = useState(false);
  const [search, setSearch] = useState("")
  // eslint-disable-next-line
  const [dataUserQuantity, setDataUserQuantity] = useState(50)
  const [currentPage, setCurrentPage] = useState(1)


  const [formValues, setFormValues] = useState({
    uid: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    rol: "",
    dateBirth: "",
  });
  const onInputChanged = ({ target }: any) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };
  const abrirModal = (user: any) => {
    setFormValues({
      uid: user._id,
      name: user.name,
      phone: user.phone,
      email: user.email,
      address: user.address,
      rol: user.rol,
      dateBirth: user.dateBirth,
    });
    console.table(user);

    setIsOpen(true);
  };

  const cerrarModal = () => {
    setSelectedUser("");
    setIsOpen(false);
  };

  const modalDelete = (user: any) => {
    setFormValues({
      uid: user._id,
      name: user.name,
      phone: user.phone,
      email: user.email,
      address: user.address,
      rol: user.rol,
      dateBirth: user.dateBirth,
    });

    setIsOpenDelet(true)

  };

  const closeModalDelete = () => {

    setIsOpenDelet(false)

  };

  const modalReservations = (user: any) => {
    setFormValues({
      uid: user._id,
      name: user.name,
      phone: user.phone,
      email: user.email,
      address: user.address,
      rol: user.rol,
      dateBirth: user.dateBirth,
    });
    setSelectedUser(user._id);
    console.log("Esto se esta enviando a my reservations", formValues.uid, formValues.name);
    setOpenUserReservations(true);
  };

  const closeModalReservations = () => {
    setFormValues({
      uid: "",
      name: "",
      phone: "",
      email: "",
      address: "",
      rol: "",
      dateBirth: "",
    })

    setOpenUserReservations(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (openUserReservations && !event.target.closest(".modal-content")) {
        closeModalReservations();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openUserReservations]);



  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { uid, name, phone, email, address, rol, dateBirth } = formValues;

    ///users = modificarUsuarioPorId(id, nombre, telefono);
    //setNombre("");
    //setTelefono("");
    try {
      // Aquí llamamos a updateUser con el id del usuario de la api y los nuevos valores de nombre y teléfono
      updateUser(uid, name, phone, email, address, rol, dateBirth);

      // getUsers();

      cerrarModal(); // Cerramos el modal después de enviar el formulario
    } catch (error) {
      console.error("Error al actualizar el usuario:", name);
    }
    cerrarModal();
  };

  const profilePic = imgUrl || "";

  useEffect(() => {
    getUsers();
  }, []);
  // Manejar clic fuera del modal para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (isOpen && !event.target.closest(".modal-content")) {
        cerrarModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  // Manejar tecla Escape para cerrar el modal
  useEffect(() => {
    const handleEscKey = (event: any) => {
      if (isOpen && event.keyCode === 27) {
        cerrarModal();
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isOpen]);


  //Borrar usuario:
  const handleDelete = (event: any) => {

    event.preventDefault();

    const userId = formValues.uid;
    try {
      deleteUser(userId);
      closeModalDelete();
    } catch (error) {
      console.error("Error al eliminar el usuario:", error);
    }

  };

  //Busqueda de usuario

  const searcher = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  //Filtrado y paginacion de usuarios

  const finishIndex = currentPage * dataUserQuantity;
  const startIndex = finishIndex - dataUserQuantity;

  let results = []
  if (!search) {
    results = users.slice(startIndex, finishIndex)
  } else {
    results = users.filter((user: any) => (
      user.name.toLowerCase().includes(search.toLocaleLowerCase()) ||
      user.phone.toLowerCase().includes(search.toLocaleLowerCase()) ||
      user.email.toLowerCase().includes(search.toLocaleLowerCase())
    ))
  }

  //Paginacion:
  let totalUser = users.length; //indice inicial
  const totalPages = Math.ceil(totalUser / dataUserQuantity);

  const nextPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 6) {
      // Si hay menos de 6 páginas, mostramos todas
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        // Mostrar las primeras 3 páginas y los puntos
        pageNumbers.push(1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Mostrar las últimas 3 páginas y los puntos
        pageNumbers.push(1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages);
      } else {
        // Mostrar páginas alrededor de la página actual
        pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }
    return pageNumbers;
  };





  return (
    <>
      <div className=" bg-black">

        <div className="rounded-none border-b-2 border-purple-600 bg-black z-0 sticky top-0 shadow-md mb-8 p-4">
          <div className="mb-4 flex flex-col justify-center gap-8  md:items-center">
            <div className="">
              <Typography placeholder="" color="gray" className="mt-1 font-normal text-gray-400">
                Cantidad de usuarios: <span className="font-bold ">{users.length}</span>
              </Typography>
            </div>
            <div className="flex w-full shrink-0 gap-2 md:w-max">
              <div className="w-full md:w-72">
                <Input
                  value={search}
                  crossOrigin={""}
                  label="buscar por nombre email o numero"
                  placeholder="search"
                  className=" placeholder:text-gray-400 text-gray-400 "
                  icon={<MagnifyingGlassIcon className="h-5 text-white mt-[10px] w-5" />}
                  onChange={searcher}
                />
              </div>
            </div>
          </div>
        </div>




        <table className="min-w-full divide-y divide-gray-400">
          <thead className="bg-black">
            <tr >
              <th
                scope="col"
                className="px-6 py-3 text-left text-md font-bold text-gray-400 uppercase tracking-wider"
              >
                Imagen
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-md font-bold text-gray-400 uppercase tracking-wider"
              >
                Nombre
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-md font-bold text-gray-400 uppercase tracking-wider"
              >
                Teléfono
              </th>
              <th
                scope="col"
                className="px-6 hidden lg:flex py-3 text-left text-md font-bold text-gray-400 uppercase tracking-wider"
              >
                Email
              </th>

            </tr>
          </thead>
          <tbody className="bg-black divide-y divide-slate-600 ">
            {results.map((user: any) => (
              <tr key={user._id} className="hover:bg-purple-950">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={profilePic}
                        alt={user.name}
                      />
                    </div>
                  </div>
                </td>
                <td className="px-6  py-4 whitespace-nowrap">
                  <div className="text-sm font-bold text-gray-400 uppercase">{user.name}</div>
                </td>
                <td className="px-6 py-4  whitespace-nowrap">
                  <div className="text-sm text-gray-400">{user.phone}</div>
                </td>
                <td className="px-6 py-4  whitespace-nowrap">
                  <div className="text-sm hidden lg:flex text-gray-400">{user.email}</div>
                </td>

                <td className="px-6 py-4 whitespace-nowrap float-right">
                  <button
                    onClick={() => abrirModal(user)}
                    className="bg-blue-500 hover:bg-blue-700 text-white hover:text-white  py-2 px-2 rounded"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                      />
                    </svg>
                    {/* Aqui */}
                  </button>
                  <button
                    onClick={() => modalDelete(user)}
                    className="bg-red-500 hover:bg-red-700 text-white hover:text-white py-2 px-2 rounded ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => modalReservations(user)}

                    className="bg-green-500 hover:bg-green-700 text-white hover:text-white py-2 px-2 rounded ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="w-5 h-5">
                      <path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0" /><circle cx="12" cy="12" r="3" /></svg>
                  </button>

                  {/*<button className="bg-green-500 hover:bg-green-300 text-white  py-2 px-2 rounded ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  </button>*/}
                </td>
                <div>

                  {isOpenDelet && (
                    <div>
                      <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center h-[90%] ">
                          <div className="bg-black rounded-lg min-w-[300px] border-purple-600 border-[1px] shadow-lg p-2 modal-content">
                            <div className=" flex justify-end mb-3">
                              <button
                                onClick={() => closeModalDelete()}
                                className=" hover:text-red-600  text-red-500 text-xl rounded-full w-6 h-6 text-center justify-center items-center flex ">
                                x
                              </button>
                            </div>


                            <p className=" text-center text-white text-lg">Desea eliminar el siguiente usuario?</p>
                            <p className=" text-white text-center text-2xl font-bold mt-3">{formValues.name}</p>

                            <div className="mt-5 flex justify-center items-center">

                              <button
                                className="bg-red-500 my-2 hover:bg-red-700 flex items-center justify-center text-white hover:text-gray-300 font-bold py-2 px-4 rounded"
                                onClick={handleDelete}
                              >
                                <i className="text-3xl las text-yellow-300 las la-exclamation-circle"></i> Eliminar
                              </button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isOpen && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-center justify-center h-[90%]  p-4">
                        <div className="bg-black border-purple-600 border-[1px] relative rounded-lg min-w-[300px] mt-20  p-6 modal-content">

                          <div className=" flex justify-between">
                            <h2 className="text-xl text-gray-300 font-bold mb-4">
                              Editar Usuario
                            </h2>

                            <button
                              onClick={() => cerrarModal()}
                              className=" absolute right-4 top-2 shadow-sm  hover:text-red-500 text-red-600 text-2xl  ">
                              x
                            </button>

                          </div>



                          <form onSubmit={handleSubmit}>

                            <div>
                              <button>

                              </button>
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="nombre"
                                className="block text-sm text-gray-300 font-bold mb-2"
                              >
                                Nombre:
                              </label>
                              <input
                                type="text"
                                id="nombre"
                                name="name"
                                value={formValues.name}
                                onChange={onInputChanged}
                                className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="telefono"
                                className="block text-sm text-gray-300 font-bold mb-2"
                              >
                                Teléfono:
                              </label>
                              <input
                                type="tel"
                                id="telefono"
                                name="phone"
                                value={formValues.phone}
                                onChange={onInputChanged}
                                className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>

                            <div className="mb-4">
                              <label
                                htmlFor="email"
                                className="block text-sm text-gray-300 font-bold mb-2"
                              >
                                Email:
                              </label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                value={formValues.email}
                                onChange={onInputChanged}
                                className="w-full border-gray-300 text-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="address"
                                className="block text-sm text-gray-300 font-bold mb-2"
                              >
                                Dirección:
                              </label>
                              <input
                                type="Text"
                                id="address"
                                name="address"
                                value={formValues.address}
                                onChange={onInputChanged}
                                className="w-full border-gray-300  rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="dateBirth"
                                className="block text-sm text-gray-300 font-bold mb-2"
                              >
                                Fecha de nacimiento:
                              </label>
                              <input
                                type="Text"
                                id="dateBirth"
                                name="dateBirth"
                                value={formValues.dateBirth}
                                onChange={onInputChanged}
                                className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                            <div className="mb-4">
                              <label htmlFor="rol" className="block text-sm text-gray-300 font-bold mb-2">Roles</label>
                              <select
                                name="rol"
                                id="rol"
                                className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                onChange={onInputChanged}
                              >
                                <option value={formValues.rol} hidden selected>
                                  {formValues.rol}
                                </option>
                                <option value="admin">admin</option>
                                <option value="center">center</option>
                                <option value="user">user</option>
                              </select>
                            </div>

                            <div className=" flex flex-col ">
                              <button
                                type="submit"
                                className="bg-blue-500  hover:bg-blue-700 text-white hover:text-gray-300 font-bold py-2 px-4 rounded"
                              >
                                Guardar
                              </button >


                            </div>

                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </tr>
            ))}
          </tbody>
        </table>

        {openUserReservations && (
          <div>
            <div className="fixed z-10 inset-0 overflow-auto">
              <div className="flex  items-center justify-center m-10 ">
                <div className="bg-black border-[1px] border-purple-600 relative rounded-xl min-w-[675px] w-750 h-[100%]  shadow-lg p-8   modal-content">
                  <div className="absolute right-3 top-3 flex justify-center  ">
                    <button
                      onClick={() => closeModalReservations()}
                      className=""
                    >
                      <X className="w-7 h-7 text-red-600 hover:text-red-500" />
                    </button>
                  </div>


                  <MyReservation userId={formValues.uid} />

                </div>
              </div>
            </div>
          </div>
        )}

      </div >
      <div className="flex flex-col bg-black text-white justify-center items-center p-6">
        <span className="font-bold text-sm uppercase ">Pagina: {currentPage} / {totalPages}</span>
        <nav aria-label="Pagination" className="w-full bg-transparent m-3 flex flex-1 items-center justify-between rounded-md ">

          <div>
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className="relative  justify-center w-20 inline-flex items-center rounded-lg px-2 py-2 text-white border-[1px] border-white hover:text-gray-400 hover:border-gray-400 focus:z-20 focus:outline-offset-0"
            >
              <span className="font-bold">Previous</span>

            </button>
          </div>

          <div>
            {getPageNumbers().map((page, index) => (
              typeof page === 'number' ? (
                <button
                  key={index}
                  onClick={() => setCurrentPage(page)}
                  className={`relative z-10 mx-1 inline-flex items-center px-4 py-2 text-sm font-semibold ${currentPage === page ? "border-[1px] border-white rounded-lg" : ""}`}
                >
                  {page}
                </button>
              ) : (
                <span key={index} className="mx-1 px-3 py-1">...</span>
              )
            ))}
          </div>


          <div>
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className="relative justify-center w-20 inline-flex items-center rounded-lg px-2 py-2 text-white border-white hover:text-gray-400 hover:border-gray-400 font-bold border-[1px]  focus:z-20 focus:outline-offset-0"
            >
              <span className="">Next</span>
            </button>
          </div>

        </nav>

      </div>





    </>
  );
};
export default UserList;
