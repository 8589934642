import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import MapUbication from "components/MapUbication/MapUbication";
//import { TaxonomyType } from "data/types";
import SectionGridFilterCard from "containers/ListingStayPage/SectionGridFilterCard";
import { StayDataType } from "data/types";
import { DEMO_STAY_LISTINGS } from "data/listings";
import Reviews from "components/Reviews/Reviews";
import SectionPolaroid from "components/SectionPolaroid/SectionPolaroid";
import SectionMerch from "components/SectionMerch/SectionMerch";
import AlertBannerPopup from "components/AlertBannerPopup/AlertBannerPopup";



const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

function PageHome() {

  return (
    <div className="nc-PageHome relative overflow-hidden">
      
      {/* <AlertBannerPopup /> */}
      <SectionHero3 className="pt-10 lg:pt-16 lg:pb-16" />
      <div className="container mx-auto relative space-y-24 mb-24 lg:space-y-28 lg:mb-28 w-full ">
        {/* SECTION HERO */}

        <div className="container mt-0 relative space-y-24 lg:space-y-28 lg:mb-28 w-full ">
          {/* SECTION 1 */}
          {/*<SectionSliderNewCategories
            //categories={DEMO_CATS}
            uniqueClassName="PageHome_s1"
  />*/}


      </div>
      <div className="nc-PageHome relative overflow-hidden">
            {/* ... otros componentes o secciones ... */}
            <SectionGridFilterCard data={DEMO_DATA} />
            {/* ... otros componentes o secciones ... */}
          </div>




          {/*<SectionOurFeatures />*/}

          {/* SECTION */}
          {/*
          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridFeaturePlaces />
          </div>
          */}

          {/* SECTION */}
          {/*<SectionHowItWork />*/}


          {/* SECTION 1 
          <div className="relative py-16">
            <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
            <SectionSliderNewCategories
              categories={DEMO_CATS_2}
              categoryCardType="card4"
              itemPerRow={4}
              heading="Suggestions for discovery"
              subHeading="Popular places to stay that Chisfis recommends for you"
              sliderStyle="style2"
              uniqueClassName="PageHome_s2"
            />
          </div>
          */}


          {/* SECTION 
          <SectionSubscribe2 />
          */}


          {/* SECTION 
          <div className="relative py-16">
            <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
            <SectionGridAuthorBox />
          </div>
          */}



          {/* SECTION 
          <SectionGridCategoryBox />

          */}

          {/* SECTION 
          <div className="relative py-16">
            <BackgroundSection />
            <SectionBecomeAnAuthor />
          </div>
          */}


          {/* SECTION 1 
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            uniqueClassName="PageHome_s3"
          />
          */}


          {/* SECTION 
          <SectionVideos />
          */}


          {/* SECTION 
          <div className="relative py-16">
            <BackgroundSection />
            <SectionClientSay uniqueClassName="PageHome_" />
          </div>
          */}

        </div>
      <div className="p-5 mt-32 "><MapUbication /></div>
      <div className=" m-24 p-5 mt-32 justify-center flex"><SectionHowItWork /></div>
      <div className=" m-5 p-5 mt-32 justify-center flex"><Reviews/></div>
      <div className=" m-0 mb-5 p-5 mt-5"><SectionPolaroid/></div>
      <div className=" m-0 mb-72 p-5 mt-5"><SectionMerch/></div>
    </div>
  );
}

export default PageHome;
