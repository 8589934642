
import UserList from "components/UserList/UserList";
//import UserListPag from "components/UserList/UserListPag"
import { useState } from "react";

import Sidebar from "shared/Sidebar/Sidebar";




const PageUsers= () => {
  const [sideMenuIsExpand, setSideMenuIsExpand] = useState(true);
 

  
  return (
    <div>
    <Sidebar setExpand={setSideMenuIsExpand} />
      {/* content */}
      <div
        className={`flex-1 min-h-screen mx-0 bg-slate-100 transition-all duration-300 ease-in-out ${
          sideMenuIsExpand ? "md:ml-72" : "md:ml-20"
        }`}
      >
      
     <UserList/>
    </div>
    </div>

  );
};
export default PageUsers;