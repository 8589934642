import { FC, useEffect, useState } from "react";
//import moment from "moment";
import "./NavBarCalendarRoom/NavBarCalendarRoom";
import { es } from "date-fns/locale";
import "./CalendarRoom.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import MenuBar from "shared/MenuBar/MenuBar";
import { useReservationStore, useSlotStore } from "../../hooks";
import { Car } from "shared/Navigation/InformacionCarrito";
import { useRoomStore } from "hooks/useRoomStore";
import {  utcToZonedTime, format } from 'date-fns-tz';

//import { startOfDay,addDays } from 'date-fns';


<link
  rel="stylesheet"
  href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css"
></link>;


interface Slot {
  id: number;
  room: string;
  start: string;
  end: string;
  price: string;
  dayOfWeek: string;
}
interface Room {
  capacidad: string;
  capacidadMin: string;
  id: string;
  images: Array<{ url: string }>;
  name: string;
  services: Array<{
    a: string;
    b: string;
    c: string;
    d: string;
    e: string;
  }>;
}
interface PropsRoomCalendar {
  slots?: Slot[];
  rooms?: Room[];
}

/*Codigo para agregar sala a carrito */

/*.... */

const CalendarRoom: FC<PropsRoomCalendar> = () => {
  const moment = require('moment-timezone');
  const timeZone = 'Europe/Madrid';
  


  const fecha = moment().tz(timeZone).toDate();
  
  const now = moment().tz(timeZone);
  
  

  const midnight = moment().tz(timeZone).startOf('day').toDate(); // 00:00 del día de hoy
  const earlyMorningCutoff = moment().tz(timeZone).startOf('day').add(3, 'hours').toDate(); // Definir las 03:00 am como límite

  const dia = format(fecha, 'd');
  const mes = format(fecha, 'M');
  const año = format(fecha, 'yy');
  // Convertir día y mes a números
  const diaNumero = Number(dia);
  const mesNumero = Number(mes);
  const diaFormateado = diaNumero < 10 ? '0' + dia : dia;
  const mesFormateado = mesNumero < 10 ? '0' + mes : mes;

  const [fechaSeleccionada, setFechaSeleccionada] = useState<Date>(fecha);
  const [fechaCodeSlot, setFechaCodeSlot] = useState<string>(`${diaFormateado}${mesFormateado}${año}`);

  // const [salaSeleccionada, setSalaSeleccionada] = useState<SalaHorario | null>(null);
  //Aqui trae el json que contiene los slot de las salas
  const { reservations, getReservations, addElementCar, car } =
    useReservationStore();

  const { getSlots, slots } = useSlotStore();
  const { rooms, getRooms } = useRoomStore();
  const [isToday, setIsToday] = useState<boolean>(false);



  //aqui se actualiza dependiendo si el slot se borra en el carrito, vuelve a su estado normal
  const isSlotReserved = (slotId: string) => {
    return car.some((item: Car) => item.id === slotId);
  };



  const handleFechaChange = (date: Date) => {
    const zonedDate = utcToZonedTime(date, timeZone);
    setFechaSeleccionada(zonedDate);
    console.log("Fecha seleccionada", zonedDate);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    const hours = (`0${date.getHours()}`).slice(-2);
    const minutes = (`0${date.getMinutes()}`).slice(-2);
    const seconds = (`0${date.getSeconds()}`).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000+02:00`;
  }

  const handlePrevDay = () => {
    // Convertir la fecha seleccionada a la zona horaria de Madrid
    const fechaSeleccionadaMadrid = moment(fechaSeleccionada).tz(timeZone);

    // Restar un día a la fecha seleccionada
    const prevDayMadrid = fechaSeleccionadaMadrid.subtract(1, 'day');

    // Convertir de nuevo a un objeto Date
    const prevDay = prevDayMadrid.toDate();

    // Actualizar la fecha seleccionada
    setFechaSeleccionada(prevDay);

    // Lógica para actualizar los datos de acuerdo a la fecha seleccionada
};

  const handleNextDay = () => {
    // Obtener la fecha seleccionada en la zona horaria de Madrid
    const fechaSeleccionadaMadrid = moment(fechaSeleccionada).tz(timeZone);

    // Añadir un día a la fecha seleccionada
    const nextDayMadrid = fechaSeleccionadaMadrid.add(1, 'day');

    // Convertir de nuevo a un objeto Date
    const nextDay = new Date(nextDayMadrid.format());

    // Actualizar la fecha seleccionada
    setFechaSeleccionada(nextDay);

    // Lógica para actualizar los datos de acuerdo a la fecha seleccionada
};


  useEffect(() => {
    getSlots({ dayOfWeek: fechaSeleccionada.getDay() });
    getReservations({ date: fechaSeleccionada });
    getRooms();
    setIsToday(moment(fechaSeleccionada).isSame(moment(), 'day'));
    

    //Es para que cada slot tenga un id unico
    const dia = fechaSeleccionada.getDate();
    const mes = fechaSeleccionada.getMonth() + 1;
    const año = fechaSeleccionada.getFullYear() % 100;

    const diaFormateado = dia < 10 ? '0' + dia : dia;
    const mesFormateado = mes < 10 ? '0' + mes : mes;
    setFechaCodeSlot(`${diaFormateado}${mesFormateado}${año}`);
    //--------

  }, [fechaSeleccionada]);




  return (
    <div>
      <div className="filtros">
        <i className="las la-sliders-h"></i>
        <a href="/#">Filtro</a>
      </div>

      <div className="inputDate mb-10">
        <button className="leftArrow" onClick={handlePrevDay} style={{ visibility: moment(fechaSeleccionada).tz(timeZone).isSameOrBefore(moment(), 'day') ? 'hidden' : 'visible' }}>{"<"}</button>
        <DatePicker
          id="fechaSelector"
          selected={fechaSeleccionada}
          onChange={handleFechaChange}
          dateFormat="EEEE dd 'de' MMM"
          locale={es}
          minDate={new Date()}
          maxDate={moment().add(3, 'months').toDate()}

        />
        <i className="las la-calendar-minus"></i>
        <button className="leftArrow" onClick={handleNextDay}>{">"}</button>
      </div>

      {/* <div className="NavCalendarRoom">
        <NavBarCalendarRoom />
      </div> */}

      <div className="sala-horario-container">
        {rooms.map((room: Room) => (
          <div key={room.id} className="sala-item">
            <div className="horarios-disponibles">
              <div className="salaImage">
                <h3>{room.name}</h3>
                {/* <p className="sala-capacidad">{sala.capacidad}</p> */}
                <p className="sala-ubicacion">{/*sala.ubicacion*/}</p>
                <img src={room.images[0].url} alt={room.name} />
                <div className="absolute top-3 left-5 rounded-2xl bg-purple-600 text-sm text-white p-1">
                  <i className="las la-user text-[16px]"></i> Min{" "}
                  {room.capacidadMin} / Max {room.capacidad}
                </div>
              </div>
              <div className="contenedor-horario-slot">
                {
                  slots
                    .filter((slot: Slot) => slot.room === room.id)
                    .map((slot: Slot, index: number) => {
                      // Verificar si el slot actual está reservado
                      const isReserved = reservations.some(
                        (reservation: any) => reservation.slot === `${fechaCodeSlot}${slot.id}`
                      );

                      


                     
                     
                     
                      let isPast = false;
                      const fechaSeleccionadaT = moment(fechaSeleccionada).tz(timeZone);
                   if(isToday){
                        
                        const slotStart = moment(`${moment(fechaSeleccionadaT).tz(timeZone).format("YYYY-MM-DD")}T${slot.start}`);
                         isPast = isToday && now.isAfter(slotStart) && !(slotStart.isAfter(midnight) && slotStart.isBefore(earlyMorningCutoff)) && slotStart.isAfter(earlyMorningCutoff);
                       
                      
                    
                      }  
                      return (
                        <div
                          key={index}
                          className={`horario-slot  ${isReserved ? "reservado" : ""} 
                          ${isSlotReserved(`${fechaCodeSlot}${slot.id}`) ? "sala-reservada" : ""}
                          ${isPast ? "no-disponible" : ""}`}
                        >
                          {!isReserved && !isPast ? (
                            <div className="">
                              {isSlotReserved(`${fechaCodeSlot}${slot.id}`) ? (
                                <p className="mt-5 sm:mt-5 md:mt-5 lg:mt-0 xl:mt-0">
                                  Añadida Al Carrito
                                </p>
                              ) : (
                                <div>
                                  {slot.start} - {slot.end}
                                  <br />
                                  <p className="precio">{slot.price}€ /Pers.</p>
                                </div>

                              )}

                              <div>

                                <MenuBar
                                  className="reservar"
                                  showIcon={false}
                                  onClick={(e) => {
                                    // eslint-disable-next-line  
                                    const fechaSeleccionadaMadrid = moment(fechaSeleccionada).tz('Europe/Madrid').toISOString();
                                    addElementCar(
                                      room.id,
                                      room.name,
                                      room.images[0].url,
                                      slot.price.replace(",", "."),
                                      (parseFloat(slot.price.replace(",", ".")) * parseInt(room.capacidadMin)).toFixed(2),
                                      slot.start,
                                      slot.end,
                                      `${fechaCodeSlot}${slot.id}`,
                                      room.capacidad,
                                      room.capacidadMin,
                                      moment(fechaSeleccionada).tz('Europe/Madrid').format(),
                                    );
                                  }}
                                />



                              </div>




                            </div>


                          ) : (
                            <div>
                              <p>{slot.start} - {slot.end}</p>
                              <span>No Disponible</span>
                            </div>

                          )}
                        </div>
                      );
                    })
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div >
  );
};

export default CalendarRoom;
