import React from 'react'
import Sidebar from 'shared/Sidebar/Sidebar'
import { useState } from 'react';
import { TablaReservas } from 'components/Table/TablaReservas';

const PageReport = () => {
    const [sideMenuIsExpand, setSideMenuIsExpand] = useState(true);
    return (
        <>
            <div>
                <Sidebar setExpand={setSideMenuIsExpand} />
                
                <div
                    className={`flex-1 min-h-screen  mx-0 bg-black p-5 transition-all duration-300 ease-in-out ${sideMenuIsExpand ? "md:ml-72" : "md:ml-20"
                        }`}
                >
                    <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                        <h1 className='text-slate-100 text-center mt-0 text-4xl uppercase font-bold'>Reporte</h1>
                        <TablaReservas />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageReport