import React, { useState, useEffect } from 'react';
import { useReservationStore } from 'hooks'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,


} from '@mui/material';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
//import { tr } from 'date-fns/locale';
//import { Search } from "lucide-react";
//import { SearchIcon } from 'lucide-react';
import { CalendarArrowUp, CalendarArrowDown } from 'lucide-react';

interface Report {
  fecha: string;
  date:
  {
    userName: string,
    userPhone: string,
    roomName: string,
    timeTable: string,
    totalPayed: string,
    orderPayment: string,
  }[];

}


interface DateRange {
  startDate: Date | null
  endDate: Date | null
}




const transformReservations = (data: any): Report[] => {
  if (!data || typeof data !== 'object') {
    return [];
  }

  const transformed: Report[] = [];
  Object.keys(data).forEach(date => {
    transformed.push({
      fecha: date,
      date: data[date].map((reserva: any) => ({
        userName: reserva.userName,
        userPhone: reserva.userPhone,
        roomName: reserva.roomName,
        timeTable: reserva.timeTable,
        totalPayed: reserva.totalPayed,
        orderPayment: reserva.orderPayment,
      }))
    });
  });
  return transformed;
};



export function TablaReservas() {
  const [filtro, setFiltro] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const { getReservationsByDates } = useReservationStore();
  const [reports, setReports] = useState<Report[]>([]);
  //const [reservationDate, setReservationDate] = useState<ReservationsByDate>({});
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  })
  //const [aux, setAux] = useState(0)


  // Filtrar las reservas por usuario o teléfono
  const reservasFiltradas = (reports || []).filter((reserva) =>
    reserva.date.some(
      (entry) =>
        entry.userName.toLowerCase().includes(filtro.toLowerCase()) ||
        entry.userPhone.includes(filtro)
    )
  );



  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  //CONTROL DE FECHAS
  const handleStartDateChange = (date: Date | null) => {
    setDateRange((prev) => ({ ...prev, startDate: date }))
  }

  const handleEndDateChange = (date: Date | null) => {
    setDateRange((prev) => ({ ...prev, endDate: date }))
  }

  const handleSubmit = async () => {
    const fetchReservations = async () => {
      try {
        const data = await getReservationsByDates(dateRange.startDate, dateRange.endDate);

        const transformedData = transformReservations(data);
        setReports(transformedData);
        console.log("Reservas obtenidas:", transformedData);
      } catch (error) {
        console.error("Error al obtener reservas:", error);
      }
    };

    fetchReservations();
  };


  //Darle formato a la fecha
  const formatFecha = (fecha: string) => {
    const [year, month, day] = fecha.split('-');
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    console.log("Valor actual de reservations:", reports);
  }, [reports]);

  //Total de reservas
  const getTotalReservas = () => {
    return reports.reduce((total, report) => total + report.date.length, 0);
  };

  //Total facturado
  const getTotalBilled = () => {
    return reports.reduce((total, report) => {
      return total + report.date.reduce((subtotal, entry) => {
        return subtotal + parseFloat(entry.totalPayed);
      }, 0);
    }, 0);
  };




  return (
    <>

      <div className="w-full flex justify-center  items-center mx-auto p-4 mt-10">
        <div className="flex items-center gap-2 p-2 bg-black border-slate-100 border rounded-full shadow-lg">
          <div className="relative">
            <DatePicker
              selected={dateRange.startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              placeholderText="Fecha de inicio"
              dateFormat="dd-MM-yyyy"
              className=" w-[100px] md:w-[200px] lg:w-[200px]  p-2 text-left font-normal border-0 focus:ring-0 cursor-pointer"
            />
          </div>
          <CalendarArrowDown className="h-4 w-4 text-gray-500 " />

          <div className="w-px h-6 bg-gray-200" />

          <div className="relative">
            <DatePicker
              selected={dateRange.endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              minDate={dateRange.startDate}
              placeholderText="Fecha final"
              dateFormat="dd-MM-yyyy"
              className="w-[100px] md:w-[200px] lg:w-[200px] p-2 text-left font-normal border-0 focus:ring-0 cursor-pointer"
            />

          </div>
          <CalendarArrowUp className="h-4 w-4 text-gray-500 " />

          <div className="w-px h-6 bg-gray-200" />

          <div className="flex-1 flex items-center">
            <TextField
              placeholder="Buscar reserva"
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}

              sx={{

                flex: 1,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "9999px", // Borde redondeado
                  "& fieldset": {
                    borderColor: "gray", // Color del borde en estado normal
                  },
                  "&:hover fieldset": {
                    borderColor: "darkgray", // Color del borde al pasar el mouse
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "lightgray", // Color del borde al enfocar
                  },
                },
                "& .MuiInputBase-input": {
                  padding: "8px 16px", // Ajusta el padding interno
                },

              }}

            />

          </div>

        </div>

        <div className=' flex justify-center items-center'>

          <button
            onClick={handleSubmit}
            className="p-2 rounded-full uppercase bg-purple-700 h-12 w-24 hover:bg-purple-600 text-white hover:text-white ml-2"
          >
            Buscar
            {/*<Search className="h-4 w-4" />*/}
          </button>

        </div>
      </div>




      <div className=' mt-16'>

        {reports.length === 0 ? (
          <p></p>
        )
          : (

            <div>
              <section className='p-2'>
                <p className=' uppercase text-right text-slate-100'>Total Facturado:
                  {" "}{getTotalBilled()}€
                </p>
                <p className=' uppercase text-right text-slate-100'>Total reservas:
                  {" "}{getTotalReservas()}
                </p>

              </section>

              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "black",
                  border: "1px solid gray",
                  borderRadius: "15px",
                }}
              >

                <Table

                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}>Fecha</TableCell>
                      <TableCell sx={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}>Usuario</TableCell>
                      <TableCell sx={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}>Teléfono</TableCell>
                      <TableCell sx={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}>Sala</TableCell>
                      <TableCell sx={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}>Horario</TableCell>
                      <TableCell sx={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}>Importe</TableCell>
                      <TableCell sx={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}>Estado de pago</TableCell>
                      <TableCell sx={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}>Nº Pedido</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reservasFiltradas
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((reserva, index) => (
                        <React.Fragment key={index}>
                          <TableRow className="hover:bg-purple-700 ">
                            <TableCell className='border-r border-white border-1 ' sx={{ color: "white" }} rowSpan={reserva.date.length + 1}>
                              {formatFecha(reserva.fecha)}
                            </TableCell>
                          </TableRow>
                          {reserva.date.map((entry, subIndex) => (
                            <TableRow key={`${index}-${subIndex}`} className="hover:bg-purple-700 flex">
                              <TableCell sx={{ color: "white" }}>{entry.userName}</TableCell>
                              <TableCell sx={{ color: "white" }}>{entry.userPhone}</TableCell>
                              <TableCell sx={{ color: "white" }}><p className={` rounded-full w-full text-center p-1 ${entry.roomName.toLowerCase() === "sala suite" ?
                                "bg-blue-600"
                                : entry.roomName.toLowerCase() === "sala 3" ? "bg-yellow-600"
                                  : entry.roomName.toLowerCase() === "sala 2" ? "bg-emerald-600"
                                    : entry.roomName.toLowerCase() === "sala 1" ? "bg-purple-600"
                                      : entry.roomName.toLowerCase() === "sala 4" ? "bg-amber-950"
                                        : entry.roomName.toLowerCase() === "sala 5" ? "bg-slate-300 text-black"
                                          : "bg-gray-700"}`}>{entry.roomName}</p></TableCell>
                              <TableCell sx={{ color: "white" }}>{entry.timeTable}</TableCell>
                              <TableCell sx={{ color: "white" }}>{entry.totalPayed}€</TableCell>
                              <TableCell sx={{ color: "white" }}>Pagado</TableCell>
                              <TableCell sx={{ color: "white" }}>{entry.orderPayment}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='mt-5'
                rowsPerPageOptions={[500, 1000, 1500]}
                component="div"
                count={reservasFiltradas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ color: "white" }}
              />

            </div>

          )}



      </div>




    </>
  );
}
